<script setup>
import { VDataTableServer, VDataTableFooter } from 'vuetify/labs/VDataTable'
import { $getSignTexts } from '@/api/settings'
import { debounce } from 'lodash'

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  glossaryGroupId: { type: Number, default: null },
  glossaryPictogramCatalogueId: { type: Number, default: null },
  isPictogramOnly: { type: Boolean, default: false },
  isAddPictogram: { type: Boolean, default: false },
  isAddPictogramItem: { type: Boolean, default: false },
  isAddGlossaryItem: { type: Boolean, default: false },
  isUpdateGlossaryItem: { type: Boolean, default: false },
  updateGlossaryItemTextFields: { type: Array, default: () => ([]) },
  updateGlossaryItemIsCustomText: { type: Boolean, default: false },
})

const emit = defineEmits(['update:modelValue', 'onCreateSignBlocks', 'onUpdatePictogram', 'onAddPictograms',
  'onClose', 'onAddGlossaryItems', 'onUpdateGlossaryItem', 'onDeleteBlockItem',])

const glossaryItems = ref([])
const glossaryItemsLoading = ref(false)
const glossaryItemsCount = ref(0)
const glossaryItemsSearch = ref('')
const selectedGlossaryItemsIds = ref([])
const selectedGlossaryFields = ref(['text_1', 'text_2'])

const isCustomTextGlossaryItem = ref(false)
const signBlockText1 = ref('')
const signBlockText2 = ref('')

const tableHeaders = [
  { key: 'id', title: 'ID', sortable: false, align: '' },
  { key: 'name', title: 'Name', sortable: false, align: '' },
  { key: 'text_1', title: 'Language 1', sortable: false, align: '' },
  { key: 'text_2', title: 'Language 2', sortable: false, align: '' },
  { key: 'pic', title: 'Pictogram', sortable: false, align: '' },
]

const computedTableHeaders = computed(() => {
  if (props.isPictogramOnly || props.isAddPictogram || props.isAddPictogramItem) {
    return tableHeaders.filter(i => ['id', 'name', 'pic'].includes(i.key))
  }
  else if (isCustomTextGlossaryItem.value) {
    return tableHeaders.filter(i => ['text_1', 'text_2'].includes(i.key))
  }
  return tableHeaders.filter(i => ['id', 'name', 'text_1', 'text_2'].includes(i.key))
})

const isPictogramView = computed(() => {
  return !!(props.isPictogramOnly || props.isAddPictogram || props.isAddPictogramItem);
})

const isDisabledAddBtn = computed(() => {
  return !selectedGlossaryItemsIds.value.length ||
  (!selectedGlossaryFields.value.length && !isPictogramView.value) ||
  (isCustomTextGlossaryItem.value && (
    (!signBlockText1.value && selectedGlossaryFields.value.includes('text_1')) ||
    (!signBlockText2.value && selectedGlossaryFields.value.includes('text_2')))
  )
})

const glossaryItemTableOptions = ref({
  itemsPerPage: 15,
  page: 1,
  sortBy: []
})

const itemsPerPageOptions = [
  { value: 10, title: '10' },
  { value: 15, title: '15' },
  { value: 20, title: '20' },
  { value: 50, title: '50' },
]

const getGlossaryItems = async () => {
  glossaryItemsLoading.value = true
  const filters = {
    page: glossaryItemTableOptions.value.page,
    per_page: glossaryItemTableOptions.value.itemsPerPage,
    search: glossaryItemsSearch.value,
  }

  if (props.isPictogramOnly || props.isAddPictogramItem || props.isAddPictogram) {
    // If no glossaryPictogramCatalogueId
    if (!props.glossaryPictogramCatalogueId) {
      glossaryItems.value = []
      glossaryItemsCount.value = 0
      glossaryItemsLoading.value = false
      return
    }

    filters.is_pictogram = true
    // Filter based on selected Campaign Glossary Pictogram Catalogue
    filters.pictogram_catalogue = props.glossaryPictogramCatalogueId ? props.glossaryPictogramCatalogueId : undefined
  } else {
    // If no glossaryGroupId - empty list
    if (!props.glossaryGroupId) {
      glossaryItems.value = []
      glossaryItemsCount.value = 0
      glossaryItemsLoading.value = false
      return
    }

    filters.is_pictogram = false
    filters.group = props.glossaryGroupId ? props.glossaryGroupId : undefined
  }
  try {
    const res = await $getSignTexts(filters)
    glossaryItems.value = res.data.results
    glossaryItemsCount.value = res.data.count
  } catch (e) {
    // If page doesn't exist goes to 1 page
    if (e.response.status === 404) {
      glossaryItemTableOptions.value.page = 1
      await getGlossaryItems()
    } else {
      console.log(e)
    }
  } finally {
    glossaryItemsLoading.value = false
  }
}

const closeDialog = () => {
  emit('update:modelValue', !props.modelValue)
}

const onCreateSignBlocks = () => {
  emit('onCreateSignBlocks', {
    selectedGlossaryItemsIds: selectedGlossaryItemsIds.value,
    selectedGlossaryFields: selectedGlossaryFields.value,
    isCustomTextGlossaryItem: isCustomTextGlossaryItem.value,
    signBlockText1: signBlockText1.value,
    signBlockText2: signBlockText2.value,
  })
  closeDialog()
}

const onAddPictograms = () => {
  emit('onAddPictograms', selectedGlossaryItemsIds.value)
  closeDialog()
}

const onAddGlossaryItems = () => {
  emit('onAddGlossaryItems', {
    selectedGlossaryItemsIds: selectedGlossaryItemsIds.value,
    selectedGlossaryFields: selectedGlossaryFields.value,
    isCustomTextGlossaryItem: isCustomTextGlossaryItem.value,
    signBlockText1: signBlockText1.value,
    signBlockText2: signBlockText2.value,
  })
  closeDialog()
}

const onUpdateGlossaryItem = () => {
  emit('onUpdateGlossaryItem', {
    selectedGlossaryItemsIds: selectedGlossaryItemsIds.value,
    selectedGlossaryFields: selectedGlossaryFields.value,
    isCustomTextGlossaryItem: isCustomTextGlossaryItem.value,
    signBlockText1: signBlockText1.value,
    signBlockText2: signBlockText2.value,
  })
  closeDialog()
}

const onUpdatePictogram = () => {
  emit('onUpdatePictogram', {
    selectedGlossaryItemsIds: selectedGlossaryItemsIds.value
  })
  closeDialog()
}

const onDeleteBlockItem = () => {
  emit('onDeleteBlockItem')
  closeDialog()
}

const onUpdateTableOptions = async ({ page, itemsPerPage, sortBy }) => {
  glossaryItemTableOptions.value.page = page
  glossaryItemTableOptions.value.itemsPerPage = itemsPerPage
  glossaryItemTableOptions.value.sortBy = sortBy
  await getGlossaryItems()
}

const onGlossaryItemsSearch = debounce(() => { getGlossaryItems() }, 500)

const onClose = async () => {
  // clear selected after hiding dialog
  selectedGlossaryItemsIds.value = []
  glossaryItemTableOptions.value.page = 1
  glossaryItemsSearch.value = ''

  // clear custom text fields
  isCustomTextGlossaryItem.value = false
  signBlockText1.value = ''
  signBlockText2.value = ''

  emit('onClose')
}

const toggleTextField = (field, newVal) => {
  if (newVal) {
    if (!selectedGlossaryFields.value.includes(field)) {
      selectedGlossaryFields.value.push(field)
    }
  } else {
    selectedGlossaryFields.value = selectedGlossaryFields.value.filter(f => f !== field)
  }
}

const onChangeCustomTextGlossaryItem = () => {
  if (glossaryItemTableOptions.value.page !== 1) {
    glossaryItemTableOptions.value.page = 1
  }

  if (isCustomTextGlossaryItem.value) {
    selectedGlossaryItemsIds.value = ['custom']
  } else {
    selectedGlossaryItemsIds.value = []
  }
}

watch(() => props.modelValue, () => {
  // on show Dialog
  if (!!props.modelValue) {
    if (props.isUpdateGlossaryItem) {
      selectedGlossaryFields.value = [...props.updateGlossaryItemTextFields]
      isCustomTextGlossaryItem.value = props.updateGlossaryItemIsCustomText
      onChangeCustomTextGlossaryItem()
    }
  }
  // on hide Dialog
  else {
  }
})
</script>

<template>
  <VDialog
    :model-value="modelValue"
    max-width="700"
    class="default-dialog"
    @after-leave="onClose"
    @update:modelValue="emit('update:modelValue')"
  >
    <VBtn
      variant="elevated"
      density="compact"
      icon="tabler-x"
      class="v-dialog-close-btn"
      @click="closeDialog"
    />

    <VCard>
      <template #title>
        <div class="d-flex align-center">
          Glossary
          {{ isPictogramOnly || isAddPictogramItem ? 'Pictograms' : 'Items' }}
          <VCheckbox
            v-if="!isPictogramView"
            v-model="isCustomTextGlossaryItem"
            class="ml-2"
            @update:model-value="onChangeCustomTextGlossaryItem"
          >
            <template #label>
              <span>
                Custom
              </span>
            </template>
          </VCheckbox>
        </div>
      </template>
      <VCardText>
        <VRow v-if="!isCustomTextGlossaryItem">
          <VCol class="py-0">
            <VTextField
              v-model="glossaryItemsSearch"
              placeholder="Search by Name, Text"
              @input="onGlossaryItemsSearch"
            >
              <template #prepend-inner>
                <VIcon
                  icon="tabler-search"
                  size="20"
                />
              </template>
            </VTextField>
          </VCol>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            class="py-0 mt-1"
          >
            <VDataTableServer
              v-model="selectedGlossaryItemsIds"
              v-model:page="glossaryItemTableOptions.page"
              :headers="computedTableHeaders"
              :items="isCustomTextGlossaryItem ? [{id: 'custom'}] : glossaryItems"
              :items-length="isCustomTextGlossaryItem ? 1 : glossaryItemsCount"
              :loading="glossaryItemsLoading"
              no-data-text="Glossary Items not found"
              hover
              item-value="id"
              density="compact"
              :select-strategy="(isPictogramOnly && !isAddPictogram) || isUpdateGlossaryItem ? 'single' : 'page'"
              show-select
              :items-per-page="glossaryItemTableOptions.itemsPerPage"
              @update:options="onUpdateTableOptions"
            >
              <template #column.text_1="{ column }">
                <div class="d-flex align-center">
                  <VCheckbox
                    :model-value="selectedGlossaryFields.includes('text_1')"
                    :disabled="isUpdateGlossaryItem"
                    @update:model-value="newVal => toggleTextField('text_1', newVal)"
                  >
                    <template #label>
                      <span
                        class="text-body-2 text-uppercase"
                        :class="{ 'text-primary': selectedGlossaryFields.includes('text_1') }"
                      >
                        {{ column.title }}
                      </span>
                    </template>
                  </VCheckbox>
                </div>
              </template>
              <template #column.text_2="{ column }">
                <div class="d-flex align-center">
                  <VCheckbox
                    :model-value="selectedGlossaryFields.includes('text_2')"
                    :disabled="isUpdateGlossaryItem"
                    @update:model-value="newVal => toggleTextField('text_2', newVal)"
                  >
                    <template #label>
                      <span
                        class="text-body-2 text-uppercase"
                        :class="{ 'text-primary': selectedGlossaryFields.includes('text_2') }"
                      >
                        {{ column.title }}
                      </span>
                    </template>
                  </VCheckbox>
                </div>
              </template>
              <template #item.text_1="{ item }">
                <VTextField
                  v-if="isCustomTextGlossaryItem && item.raw.id === 'custom'"
                  v-model="signBlockText1"
                  :disabled="!selectedGlossaryFields.includes('text_1')"
                  class="py-2"
                />
                <span v-else>
                  {{ item.raw.text_1 }}
                </span>
              </template>
              <template #item.text_2="{ item }">
                <VTextField
                  v-if="isCustomTextGlossaryItem && item.raw.id === 'custom'"
                  v-model="signBlockText2"
                  :disabled="!selectedGlossaryFields.includes('text_2')"
                  class="py-2"
                />
                <span v-else>
                  {{ item.raw.text_2 }}
                </span>
              </template>
              <template #item.pic="{ item }">
                <div
                  style="width: 50px; height: 50px"
                  class="d-flex align-center justify-center"
                >
                  <img
                    :src="item.raw.pic"
                    alt="icon"
                    style="width: 100%; height: 100%; object-fit: contain"
                  >
                </div>
              </template>
              <template #bottom>
                <div class="glossary-items-data-table-footer">
                  <VDataTableFooter
                    :items-per-page-options="itemsPerPageOptions"
                    show-current-page
                    class="mt-1"
                  />
                </div>
              </template>
            </VDataTableServer>
          </VCol>
          <VCol
            class="d-flex justify-space-between flex-wrap py-0"
            cols="12"
          >
            <div>
              <!-- If update Block Item (GlossaryItem or Pictogram) -->
              <VBtn
                v-if="isUpdateGlossaryItem || (isPictogramOnly && !isAddPictogram)"
                variant="outlined"
                append-icon="mdi-minus-circle"
                @click="onDeleteBlockItem"
              >
                <span class="text-none">
                  Delete
                </span>
              </VBtn>
            </div>
            <VBtn
              v-if="isAddPictogram"
              :disabled="!selectedGlossaryItemsIds.length"
              @click="onAddPictograms"
            >
              <span class="text-none">
                Add pictogram(s)
              </span>
            </VBtn>
            <VBtn
              v-else-if="isPictogramOnly"
              :disabled="!selectedGlossaryItemsIds.length"
              @click="onUpdatePictogram"
            >
              <span class="text-none">
                Update pictogram
              </span>
            </VBtn>
            <VBtn
              v-else-if="isAddGlossaryItem"
              :disabled="isDisabledAddBtn"
              @click="onAddGlossaryItems"
            >
              <span class="text-none">
                 Add {{ selectedGlossaryItemsIds.length }} item(s)
              </span>
            </VBtn>
            <div
              v-else-if="isUpdateGlossaryItem"
              class="d-flex flex-wrap align-center"
            >
              <VBtn
                :disabled="isDisabledAddBtn"
                @click="onUpdateGlossaryItem"
              >
                <span class="text-none">
                  Update
                </span>
              </VBtn>
            </div>
            <div
              v-else
              class="d-flex flex-column"
            >
              <VBtn
                :disabled="isDisabledAddBtn"
                @click="onCreateSignBlocks"
              >
                <span class="text-none">
                  Add {{ selectedGlossaryItemsIds.length }}
                  {{ isAddPictogramItem ? 'pictogram(s)' : 'item(s)' }}
                </span>
              </VBtn>
              <span
                v-if="!selectedGlossaryFields.length && !isPictogramView"
                class="text-primary"
              >
                Select at least 1 language field
              </span>
            </div>
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style lang="scss">
.glossary-items-data-table-footer {
  .v-data-table-footer {
    justify-content: center !important;
  }
}

.update-glossary-item-subtitle-wrapper {
  max-width: 110px;
  font-size: 10px;
  text-align: right;
  margin-right: 5px;
}
</style>
