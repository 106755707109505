<script lang="ts" setup>
import { useDrawingStore } from '@/store/drawing'
import {
  $addSignBlockItems,
  $createMultipleSignBlocks,
  $deleteMultipleSignBlocks,
  $deleteSignBlock,
  $updateMultipleSignBlockItems,
  $updateMultipleSignBlocks,
  $updateMultipleSignGlossaryRows,
  $updateSignBlock,
  $updateSignBlockItem
} from '@/api/signs'
import Draggable from 'vuedraggable'
import { useUserStore } from '@/store/user'
import { can } from '@layouts/plugins/casl'
import ability from '@/plugins/casl/ability'
import { useSnackbarStore } from '@/store/snackbar'

interface IProps {
  sign?: any,
  signSides?: number,
  signGlossaryRowsBySide?: any,
  isDrawing?: boolean,
  sides?: string[],
}

const props = withDefaults(defineProps<IProps>(), {
  sign: () => ({}),
  signSides: 1,
  signGlossaryRowsBySide: () => ({}),
  isDrawing: false,
  sides: () => (['A', 'B', 'C', 'D']),
})

interface Emit {
  (e: 'handleSignBlockData', value: any): void
  (e: 'getSignBlocks'): void
  (e: 'getActivityTimelineItems'): void
}

const emit = defineEmits<Emit>()

const drawingStore = useDrawingStore()
const userStore = useUserStore()
const snackbarStore = useSnackbarStore()

const alignmentOptions = [
  { value: 1, icon: 'tabler-align-left' },
  { value: 2, icon: 'tabler-align-center' },
  { value: 3, icon: 'tabler-align-right' }
]

const showGlossaryItemDialog = ref<boolean>(false)
const glossaryItemDialogIsAddGlossaryItem = ref<boolean>(false)
const glossaryItemDialogIsUpdateGlossaryItem = ref<boolean>(false)
const glossaryItemDialogUpdateGlossaryItemTextFields = ref<('text_1' | 'text_2')[]>([])
const glossaryItemDialogUpdateGlossaryItemIsCustomText = ref<boolean>(false)
const glossaryItemDialogIsPictogramOnly = ref<boolean>(false)
const glossaryItemDialogIsAddPictogram = ref<boolean>(false)
const glossaryItemDialogIsAddPictogramItem = ref<boolean>(false)
const glossaryItemDialogIsAddItemWithRow = ref<boolean>(false)
const glossaryItemDialogSelectedSignGlossaryRowId = ref<number | null>(null)
const glossaryItemDialogSelectedSignBlockId = ref<number | null>(null)
const glossaryItemDialogSelectedSignBlockItemId = ref<number | null>(null)

// Edit Sign Glossary Row
const showSignGlossaryRowEditDialog = ref<boolean>(false)
const selectedSignGlossaryRowToEdit = ref({})

const selectedSignBlockIds = ref<number[]>([])
const deleteSelectedSignBlocksLoading = ref<boolean>(false)

const currentTab = ref(0)

const tabs = computed(() => {
  const result = []

  if (props.sign && props.sign.type_info) {
    for (let i = 0; i <= props.sign.type_info.sides; i++) {
      result.push(i)
    }
  }

  return result
})

const isShowGlossaryBlock = computed(() => {
  if (props.isDrawing) {
    return !drawingStore.offlineMode
  }
  return true
})

const addSignBlock = (isPictogram = false, isRow = false, selectedSignGlossaryRowId = null) => {
  showGlossaryItemDialog.value = true
  if (isPictogram) {
    glossaryItemDialogIsAddPictogramItem.value = true
  }
  // Add Block to New Row
  if (isRow) {
    glossaryItemDialogIsAddItemWithRow.value = true
  }
  // Add Block to Existing Row
  else {
    glossaryItemDialogSelectedSignGlossaryRowId.value = selectedSignGlossaryRowId
  }
}

const updateSignBlockItem = async (isDeleteItem = false as boolean, data = {} as any, type = '' as string) => {
  const payload = {
    is_delete: isDeleteItem,
  } as any

  if (type === 'text') {
    const {
      selectedGlossaryItemsIds: glossaryItemsIds,
      selectedGlossaryFields: glossaryTextFields,
      isCustomTextGlossaryItem: isCustomGlossaryText,
      signBlockText1: customText1,
      signBlockText2: customText2
    } = data

    const [glossaryItemId] = glossaryItemsIds
    payload.glossary_item_id = glossaryItemId
    payload.glossary_text_fields = glossaryTextFields
    payload.is_custom_glossary_text = isCustomGlossaryText
    payload.glossary_custom_text_1 = customText1
    payload.glossary_custom_text_2 = customText2
  }
  else if (type === 'pictogram') {
    const {
      selectedGlossaryItemsIds: glossaryItemsIds,
    } = data

    const [glossaryItemId] = glossaryItemsIds
    payload.glossary_item_id = glossaryItemId
  }
  const selectedSignBlockItemId = glossaryItemDialogSelectedSignBlockItemId.value

  // If no selectedSignBlockPictogramId - return
  if (!selectedSignBlockItemId) {
    return
  }

  try {
    // return Sign
    const res = await $updateSignBlockItem(selectedSignBlockItemId, payload)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}

const createSignBlocks = async (data: any, isEmpty = false as boolean, isRow = false as boolean, signGlossaryRowId = null as number | null) => {
  const {
    selectedGlossaryItemsIds: glossaryItemsIds,
    selectedGlossaryFields: glossaryTextFields,
    isCustomTextGlossaryItem: isCustomGlossaryText,
    signBlockText1: customText1,
    signBlockText2: customText2
  } = data
  const payload = { } as any
  // Create SignGlossaryRow with SignBlock
  if (glossaryItemDialogIsAddItemWithRow.value || isRow) {
    payload.sign = props.sign.id
    payload.side_number = currentTab.value
  }
  // Create SignBlock and add to the SignGlossaryRow
  else {
    payload.sign_glossary_row =
      signGlossaryRowId ? signGlossaryRowId : glossaryItemDialogSelectedSignGlossaryRowId.value
  }

  if (isEmpty) {
    payload.is_empty = true
  }
  else if (isCustomGlossaryText) {
    payload.is_custom_glossary_text = isCustomGlossaryText
    payload.glossary_custom_text_1 = customText1
    payload.glossary_custom_text_2 = customText2
    payload.glossary_items_ids = []
  } else {
    payload.glossary_items_ids = glossaryItemsIds
  }

  payload.glossary_text_fields = glossaryTextFields
  try {
    // return Sign
    const res = await $createMultipleSignBlocks(payload)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}

// Update Sign Block Items position numbers
const updateSignBlockItemsOrder = async (signBlockItemsArr: any[]) => {
  const signBlockItems = signBlockItemsArr.map((i: any, Idx: number) => ({id: i.id, position_number: Idx}))
  try {
    // return Sign(from the first SignBlock sent)
    const res = await $updateMultipleSignBlockItems(signBlockItems)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}

// Update Sign Blocks position numbers
const updateSignBlocksOrder = async (signBlocksArr: any[]) => {
  const signBlocks = signBlocksArr.map((i: any, Idx: number) => ({id: i.id, position_number: Idx}))
  try {
    // return Sign(from the first SignBlock sent)
    const res = await $updateMultipleSignBlocks(signBlocks)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}

// Delete selected Sign Blocks
const deleteMultipleSignBlocks = async () => {
  deleteSelectedSignBlocksLoading.value = true
  const payload = {
    sign_block_ids: selectedSignBlockIds.value
  }
  try {
    // return Sign(from the first SignBlock sent)
    const res = await $deleteMultipleSignBlocks(payload)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  } finally {
    selectedSignBlockIds.value = []
    deleteSelectedSignBlocksLoading.value = false
  }
}

// Update Sign Glossary Rows position numbers
const updateSignGlossaryRowsOrder = async () => {
  const signGlossaryRows = props.signGlossaryRowsBySide[currentTab.value]
    .map((i: any, Idx: number) => ({id: i.id, position_number: Idx + 1}))
  try {
    // return Sign(from the first SignGlossaryRow sent)
    const res = await $updateMultipleSignGlossaryRows(signGlossaryRows)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}

const handleSignBlockData = (data: any) => {
  emit('handleSignBlockData', data)
  emit('getActivityTimelineItems')
}

const onCloseGlossaryItemDialog = () => {
  glossaryItemDialogIsAddGlossaryItem.value = false
  glossaryItemDialogIsUpdateGlossaryItem.value = false
  glossaryItemDialogUpdateGlossaryItemTextFields.value = []
  glossaryItemDialogUpdateGlossaryItemIsCustomText.value = false
  glossaryItemDialogIsPictogramOnly.value = false
  glossaryItemDialogIsAddPictogram.value = false
  glossaryItemDialogIsAddPictogramItem.value = false
  glossaryItemDialogIsAddItemWithRow.value = false
  glossaryItemDialogSelectedSignGlossaryRowId.value = null
  glossaryItemDialogSelectedSignBlockId.value = null
  glossaryItemDialogSelectedSignBlockItemId.value = null
}

const onClickSignBlockItem = (signBlockItem: any) => {
  if (!userStore.isCustomer && ability.can('change', 'sign')) {
    const signBlockItemType = signBlockItem.item_type === 1 ? 'text' : 'pictogram'

    if (signBlockItemType == 'text') {
      glossaryItemDialogIsUpdateGlossaryItem.value = true
      glossaryItemDialogUpdateGlossaryItemIsCustomText.value = signBlockItem?.is_custom_text || false
      if (signBlockItem.text_1) {
        glossaryItemDialogUpdateGlossaryItemTextFields.value.push('text_1')
      }
      if (signBlockItem.text_2) {
        glossaryItemDialogUpdateGlossaryItemTextFields.value.push('text_2')
      }
    }
    // type == 'pictogram'
    else {
      glossaryItemDialogIsPictogramOnly.value = true
      // change
      glossaryItemDialogIsAddPictogram.value = false
    }

    showGlossaryItemDialog.value = true
    // Pictogram ID
    glossaryItemDialogSelectedSignBlockItemId.value = signBlockItem.id
  }
}

const onAddSignBlockItem = (signBlockId: number, type: string) => {
  if (!userStore.isCustomer && ability.can('change', 'sign')) {
    if (type == 'text') {
      glossaryItemDialogIsAddGlossaryItem.value = true
    }
    // type == 'pictogram'
    else {
      glossaryItemDialogIsPictogramOnly.value = true
      // add
      glossaryItemDialogIsAddPictogram.value = true
    }
    showGlossaryItemDialog.value = true
    // Glossary Block ID
    glossaryItemDialogSelectedSignBlockId.value = signBlockId
  }
}

const addSignBlockItems = async (data: any, type: string) => {
  const signBlockId = glossaryItemDialogSelectedSignBlockId.value

  // If no signBlockId - return
  if (!signBlockId) {
    return
  }

  let payload = {
    sign_block_id: signBlockId,
  } as any

  if (type == 'text') {
    const {
      selectedGlossaryItemsIds: glossaryItemsIds,
      selectedGlossaryFields: glossaryTextFields,
      isCustomTextGlossaryItem: isCustomGlossaryText,
      signBlockText1: customText1,
      signBlockText2: customText2
    } = data

    if (isCustomGlossaryText) {
      payload.is_custom_glossary_text = isCustomGlossaryText
      payload.glossary_custom_text_1 = customText1
      payload.glossary_custom_text_2 = customText2
      payload.glossary_item_ids = []
    } else {
      payload.glossary_item_ids = glossaryItemsIds
    }

    payload.glossary_text_fields = glossaryTextFields
  }
  // type == 'pictogram'
  else {
    payload.glossary_item_ids = data
  }

  try {
    // return Sign
    const res = await $addSignBlockItems(payload)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}

const deleteSignBlock = async (signBlockId: number) => {
  // If there is no ID - return
  if (!signBlockId) {
    return
  }

  try {
    // return Sign
    const res = await $deleteSignBlock(signBlockId)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}

const onSelectAlignment = async (signBlockId: number, alignmentValue: number) => {
  try {
    await $updateSignBlock(signBlockId, { alignment: alignmentValue })
  } catch (e) {
    console.log(e)
  }
}

const calculatePictogramBlockWidth = (pictogramsCount: number | undefined) => {
  if (!pictogramsCount) return 0

  const MAX_VISIBLE = 4
  const SINGLE_WIDTH = 40

  const count = Math.min(pictogramsCount, MAX_VISIBLE)
  return `${count * SINGLE_WIDTH}px`
}

// Check if a block is selected
const isBlockSelected = (blockId: number) => selectedSignBlockIds.value.includes(blockId)

// Toggle block selection
const toggleBlockSelection = (blockId: number) => {
  const index = selectedSignBlockIds.value.indexOf(blockId)
  if (index === -1) {
    selectedSignBlockIds.value.push(blockId)
  } else {
    selectedSignBlockIds.value.splice(index, 1)
  }
}

const getDisplayText = (item: any): string => {
  const { text_1, text_2 } = item
  return [text_1, text_2].filter(Boolean).join(' ')
}
</script>

<template>
  <div v-if="isShowGlossaryBlock">
    <GlossaryItemDialog
      v-model="showGlossaryItemDialog"
      :glossary-group-id="sign?.campaign_glossary_group_id || null"
      :glossary-pictogram-catalogue-id="sign?.campaign_glossary_pictogram_catalogue_id || null"
      :is-add-glossary-item="glossaryItemDialogIsAddGlossaryItem"
      :is-update-glossary-item="glossaryItemDialogIsUpdateGlossaryItem"
      :update-glossary-item-text-fields="glossaryItemDialogUpdateGlossaryItemTextFields"
      :update-glossary-item-is-custom-text="glossaryItemDialogUpdateGlossaryItemIsCustomText"
      :is-add-pictogram="!!glossaryItemDialogIsAddPictogram"
      :is-add-pictogram-item="!!glossaryItemDialogIsAddPictogramItem"
      :is-pictogram-only="!!glossaryItemDialogIsPictogramOnly"
      @onClose="onCloseGlossaryItemDialog"
      @onCreateSignBlocks="createSignBlocks($event)"
      @onAddGlossaryItems="addSignBlockItems($event, 'text')"
      @onAddPictograms="addSignBlockItems($event, 'pictogram')"
      @onUpdateGlossaryItem="updateSignBlockItem(false, $event, 'text')"
      @onUpdatePictogram="updateSignBlockItem(false, $event, 'pictogram')"
      @onDeleteBlockItem="updateSignBlockItem(true)"
    />
    <SignGlossaryRowEditDialog
      v-model="showSignGlossaryRowEditDialog"
      :sign-glossary-row="selectedSignGlossaryRowToEdit"
      @onSubmit="emit('getSignBlocks'); emit('getActivityTimelineItems')"
    />
    <div
      v-if="!userStore.isCustomer && can('change', 'sign')"
      class="d-flex align-center flex-wrap justify-space-between"
    >
      <div class="d-flex align-center flex-wrap">
        <VBtn
          v-if="!!selectedSignBlockIds.length"
          size="small"
          class="ma-1"
          @click="deleteMultipleSignBlocks"
        >
          <VIcon icon="tabler-trash-x"/>
          <span class="text-none">
            Delete
            {{ selectedSignBlockIds.length }}
            Item(s)
          </span>
          <VProgressCircular
            v-if="deleteSelectedSignBlocksLoading"
            class="ml-1"
            size="16"
            width="2"
            indeterminate
            color="white"
          />
        </VBtn>
      </div>
      <div class="d-flex align-center flex-wrap">
<!--        <VBtn-->
<!--          size="small"-->
<!--          class="ma-1"-->
<!--          @click="addSignBlock(true, true)"-->
<!--        >-->
<!--          <VIcon icon="mdi-add"/>-->
<!--          Add Pictogram Row-->
<!--        </VBtn>-->
<!--        <VBtn-->
<!--          size="small"-->
<!--          class="ma-1"-->
<!--          @click="addSignBlock(false, true)"-->
<!--        >-->
<!--          <VIcon icon="mdi-add"/>-->
<!--          Add Glossary Item Row-->
<!--        </VBtn>-->
        <VBtn
          size="x-small"
          class="ma-1 py-0 px-3"
          @click="createSignBlocks({}, true, true)"
        >
          <VIcon icon="mdi-add"/>
          Row
        </VBtn>
      </div>
    </div>
    <VTabs
      v-model="currentTab"
      bg-color="transparent"
      grow
    >
      <VTab
        v-for="tabIndex in tabs"
        :key="tabIndex"
        :value="tabIndex"
      >
        Side {{ sides[tabIndex] }}
      </VTab>
    </VTabs>

    <VWindow v-model="currentTab">
      <VWindowItem
        v-for="tabIndex in tabs"
        :key="tabIndex"
        :value="tabIndex"
      >
        <VCard flat>
          <VCardText
            v-if="signGlossaryRowsBySide[tabIndex].length"
            class="overflow-x-auto"
          >
            <!-- Draggable Rows -->
            <Draggable
              :list="signGlossaryRowsBySide[tabIndex]"
              ghost-class="ghost-draggable"
              item-key="id"
              tag="div"
              class="v-row"
              @change="updateSignGlossaryRowsOrder"
            >
              <template #item="{ element: row }">
                <VCol
                  cols="12"
                  class="pt-1"
                  :class="{ 'sign-glossary-bottom-divider': !!row.bottom_divider }"
                >
                  <VRow class="d-flex align-center justify-space-between py-2">
                    <VChip
                      class="row-position-number-chip position-relative"
                      @click="showSignGlossaryRowEditDialog = true; selectedSignGlossaryRowToEdit = { ...row }"
                    >
                      {{ row.position_number }}
                      <VIcon
                        class="row-position-number-chip-icon"
                        icon="tabler-edit"
                      />
                      <VMenu
                        activator="parent"
                        class="default-tooltip"
                        close-delay="0"
                        location="top center"
                        offset="5"
                        open-delay="100"
                        open-on-hover
                      >
                        <span>Edit Row</span>
                      </VMenu>
                    </VChip>
                    <div
                      v-if="!userStore.isCustomer && can('change', 'sign')"
                      class="d-flex align-center flex-wrap justify-end"
                    >
                      <VBtn
                        size="x-small"
                        class="ma-1"
                        @click="createSignBlocks({}, true, false, row.id)"
                      >
                        <VIcon icon="mdi-add" size="small" />
                        Column
                      </VBtn>
                    </div>
                  </VRow>
                  <!-- Draggable Blocks inside Row -->
                  <Draggable
                    :list="row.sign_blocks"
                    ghost-class="ghost-draggable"
                    item-key="id"
                    tag="div"
                    class="v-row mt-1"
                    @change="updateSignBlocksOrder(row.sign_blocks)"
                  >
                    <template #item="{ element: block }">
                      <VCol
                        class="position-relative mx-0 px-0 py-7 border glossary-block-container d-flex align-center"
                        :class="{
                          'selected-glossary-block': isBlockSelected(block.id),
                          'justify-start' : block.alignment === 1,
                          'justify-center' : block.alignment === 2,
                          'justify-end' : block.alignment === 3
                        }"
                      >
                        <!-- Top-left corner block -->
                        <div class="glossary-alignment-toggle-block-wrapper glossary-block-content">
                          <VBtnToggle
                            v-model="block.alignment"
                            class="glossary-alignment-toggle-block"
                            color="primary"
                            density="compact"
                            mandatory
                            :rounded="false"
                            divided
                            @update:model-value="newVal => onSelectAlignment(block.id, newVal)"
                          >
                            <VBtn
                              v-for="(item, itemKey) in alignmentOptions"
                              :key="itemKey"
                              :value="item.value"
                              class="glossary-alignment-toggle-block-btn"
                              variant="outlined"
                              size=""
                            >
                              <VIcon :icon="item.icon" />
                            </VBtn>
                          </VBtnToggle>
                        </div>

                        <!-- Other table content (text and pictograms) -->
                        <!-- Draggable Items inside Block -->
                        <Draggable
                          :list="block.items"
                          ghost-class="ghost-draggable"
                          item-key="id"
                          tag="div"
                          class="d-inline-flex flex-wrap"
                          @change="updateSignBlockItemsOrder(block.items)"
                        >
                          <template #item="{ element: blockItem }">
                            <div
                              class="position-relative mx-0 px-1 glossary-block-item-container"
                              :class="{
                                'text-left' : block.alignment === 1,
                                'text-center' : block.alignment === 2,
                                'text-right' : block.alignment === 3
                              }"
                              @click.stop="onClickSignBlockItem(blockItem)"
                            >
                              <template v-if="blockItem.item_type === 1">
                                <span
                                  :class="{'glossary-item-custom-text': blockItem?.is_custom_text}"
                                >
                                  {{ getDisplayText(blockItem) }}
                                </span>
                              </template>
                              <template v-else-if="blockItem.item_type === 2">
                                <div
                                  style="width: 30px; height: 30px"
                                  class="d-flex align-center justify-center mx-0"
                                >
                                  <img
                                    :src="blockItem?.pic"
                                    alt="icon"
                                    style="width: 100%; height: 100%; object-fit: contain"
                                  />
                                </div>
                              </template>
                              <VMenu
                                v-if="!userStore.isCustomer && can('change', 'sign')"
                                activator="parent"
                                class="default-tooltip"
                                close-delay="0"
                                location="top center"
                                offset="5"
                                open-delay="100"
                                open-on-hover
                              >
                                <span>Update {{ blockItem.item_type === 1 ? 'Text' : 'Pictogram' }}</span>
                              </VMenu>
                            </div>
                          </template>
                        </Draggable>

                        <!-- Select -->
                        <div
                          v-if="!userStore.isCustomer && can('change', 'sign')"
                          class="select-glossary-item-block-wrapper glossary-block-content"
                        >
                          <VCheckbox
                            :model-value="isBlockSelected(block.id)"
                            @update:model-value="toggleBlockSelection(block.id)"
                          />
                          <VMenu
                            activator="parent"
                            class="default-tooltip"
                            close-delay="0"
                            location="top center"
                            offset="5"
                            open-delay="100"
                            open-on-hover
                          >
                            <span>{{ isBlockSelected(block.id) ? 'Unselect' : 'Select' }}</span>
                          </VMenu>
                        </div>

                        <!-- Add Pictogram -->
                        <div
                          v-if="!userStore.isCustomer && can('change', 'sign')"
                          class="add-pictogram-block-wrapper glossary-block-content"
                        >
                          <VMenu location="bottom">
                            <template #activator="{ props }">
                              <VSheet
                                v-bind="props"
                                :height="24"
                                :width="24"
                                border
                                class="add-pictogram-block d-flex align-center justify-center"
                                rounded
                              >
                                <VIcon icon="mdi-plus" />
                              </VSheet>
                            </template>
                            <VList class="ma-0 pa-0 add-sign-block-item-list">
                              <VListItem
                                class="ma-0 pa-0"
                                @click="onAddSignBlockItem(block.id, 'pictogram')"
                              >
                                <div class="text-body-2">
                                  Add Pictogram
                                </div>
                              </VListItem>
                              <VListItem
                                class="ma-0 pa-0"
                                @click="onAddSignBlockItem(block.id, 'text')"
                              >
                                <div class="text-body-2">
                                  Add Glossary Item
                                </div>
                              </VListItem>
                            </VList>
                          </VMenu>
                        </div>

                        <!-- Edit Block -->
                        <div
                          v-if="!userStore.isCustomer && can('change', 'sign')"
                          class="edit-glossary-btn-wrapper glossary-block-content"
                        >
                          <VBtn
                            class="edit-glossary-btn rounded-lg"
                            density="compact"
                            icon="tabler-trash-x"
                            variant="elevated"
                            size=""
                            @click="deleteSignBlock(block?.id)"
                          />
                          <VMenu
                            activator="parent"
                            class="default-tooltip"
                            close-delay="0"
                            location="top center"
                            offset="5"
                            open-delay="100"
                            open-on-hover
                          >
                            <span>Delete</span>
                          </VMenu>
                        </div>
                      </VCol>
                    </template>
                  </Draggable>
                </VCol>
              </template>
            </Draggable>
          </VCardText>
          <VCardText
            v-else
            class="text-center"
          >
            Glossary Items not found
          </VCardText>
        </VCard>
      </VWindowItem>
    </VWindow>
  </div>
</template>

<style lang="scss">
.row-position-number-chip {
  padding: 0 7px !important;
  height: 16px !important;
  .row-position-number-chip-icon {
    margin-left: 1px;
    font-size: 13px;
  }
}

.sign-glossary-bottom-divider {
  border-bottom: 10px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

.glossary-block-content {
  opacity: 0; /* Initially hide content */
  pointer-events: none; /* Prevent interaction when hidden */
  transition: opacity 0.4s ease; /* Smooth transition */
}

.glossary-block-container:hover .glossary-block-content {
  opacity: 1; /* Fade in on hover */
  pointer-events: auto; /* Allow interaction when visible */
}

.glossary-block-item-container {
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease; // Smooth transition
  cursor: pointer; // Default cursor
  &:hover {
    background-color: rgba(var(--v-border-color), var(--v-border-opacity)); // Light gray
  }

  &:active {
    background-color: transparent;
    cursor: grabbing; // Change cursor when actively dragging
  }
}

.glossary-alignment-toggle-block-wrapper {
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 1;
}

.selected-glossary-block {
  background: rgba(var(--v-theme-primary), 0.03);
}

.glossary-alignment-toggle-block {
  display: flex;
  height: 20px !important;
}

.glossary-alignment-toggle-block-btn {
  padding-left: 4px;
  padding-right: 4px;
}

.select-glossary-item-block-wrapper {
  position: absolute;
  top: -3px;
  right: 54px;
}

.add-pictogram-block-wrapper {
  position: absolute;
  top: 2.5px;
  right: 30px;
  z-index: 1;
  .add-pictogram-block {
    cursor: pointer;
  }
}

.add-sign-block-item-list {
  .v-list-item {
  }
}

.edit-glossary-btn-wrapper {
  position: absolute;
  top: 2.5px;
  right: 0;
  z-index: 1;
  .edit-glossary-btn {
    width: 24px;
    height: 24px;
  }
}

.add-pictogram-small-btn {
  width: 30px;
  height: 24px;
}

.add-glossary-item-small-btn {
  width: 36px;
  height: 24px;
}

.glossary-item-custom-text {
  font-weight: bold;
  color: orange;
}
</style>
